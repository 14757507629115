import { Injectable, NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, Router } from '@angular/router';
import { OopsComponent } from './oops/oops.component';
import { CheckoutCommon } from './pages/_brand-pages-base/base-controllers/checkout/checkout-common';
import { WhatIsThisPage } from '../pages/what-is-this/what-is-this';
import { SafeOrderPage } from '../pages/safe-order/safe-order';
import { DestinationComponent } from './destination/destination.component';
import { UpsellDynamic } from './upsell/upsell-dynamic';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { CcpaComponent } from './ccpa/ccpa.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: OopsComponent},
    { path: 'PrivacyPolicy', component: PrivacyPolicyComponent},
    { path: 'gdpr', component: GdprComponent},
    { path: 'ccpa', component: CcpaComponent},
    { path: 'terms', component: TermsComponent},
    { path: 'Gundry_SecureCart', component: CheckoutCommon},
    { path: 'Gundry_SecureCart_230913', component: CheckoutCommon},
    { path: 'fst/:generidUrl', component: DestinationComponent},
    { path: 'Gundry_Receipt_AP', data: { animation: 'enterLeavePage' }, loadChildren: () => import('./order-success/order-success.module').then(m => m.OrderSuccessModule) },
    { path: 'upDownDyn', component: UpsellDynamic, data: { animation: 'enterLeavePage' } },
    { path: 'what-is-this', component: WhatIsThisPage },  // TODO make a module to lazy load this page
    { path: 'safe-order', component: SafeOrderPage }, // TODO make a module to lazy load this page
    { path: 'OrderSuccess', loadChildren: () => import('./order-success/order-success.module').then(m => m.OrderSuccessModule) },
    { path: 'what-is-this', component: WhatIsThisPage },  // TODO make a module to lazy load this page
    { path: 'safe-order', component: SafeOrderPage }, // TODO make a module to lazy load this page
    { path: 'upsellrd', loadChildren: () => import('../upsellrd/upsellrd.module').then(m => m.UpsellrdModule) },

  // Braintree checkout variants
  { path: 'Gundry_SecureCart_GBP', component: CheckoutCommon, data: { braintreeTaxEstimate: 0.2, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'GBP', braintreeCurrencyConversion: 0.83, braintreeDefaultCountry: 'United Kingdom',  officialCurrency: 'GBP', currencySymbol: '£', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_GBP2', component: CheckoutCommon, data: { braintreeTaxEstimate: 0.2,  braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'GBP', braintreeCurrencyConversion: 0.83, braintreeDefaultCountry: 'United Kingdom',  officialCurrency: 'GBP', currencySymbol: '£', braintreeCountryLabel: true, checkoutFeature: 'showProductBox', showSubtotal: true } },

  { path: 'Gundry_SecureCart_EUR', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Germany', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Translate', component: CheckoutCommon, data: { braintreeLanguageTranslation: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Germany', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },

  { path: 'Gundry_SecureCart_MXN', component: CheckoutCommon, data: { braintreeLanguageTranslation: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'MXN', braintreeCurrencyConversion: 19.5, braintreeDefaultCountry: 'Mexico', officialCurrency: 'MXN', currencySymbol: 'MX$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_USD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'USD', braintreeCurrencyConversion: 1.00,  braintreeDefaultCountry: 'United States',officialCurrency: 'USD', currencySymbol: '$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_USDSub', component: CheckoutCommon, data: { braintreeSubscriptionSupported: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'USD', braintreeCurrencyConversion: 1.00,  braintreeDefaultCountry: 'United States',officialCurrency: 'USD', currencySymbol: '$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },

  { path: 'Gundry_SecureCart_AUD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'AUD', braintreeCurrencyConversion: 1.54,  braintreeDefaultCountry: 'Australia', officialCurrency: 'AUD', currencySymbol: 'A$', braintreeCountryLabel: true, braintreeLocale: 'en-AU', checkoutFeature: 'showProductBox,braintreeShowAUDPriceBox' } },


  { path: 'Gundry_SecureCart_CAD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'CAD', braintreeCurrencyConversion: 1.38,  braintreeDefaultCountry: 'Canada', officialCurrency: 'CAD', currencySymbol: 'A$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Russia', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Russia', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_China', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'China', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_JPY', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'JPY', braintreeCurrencyConversion: 150.0,  braintreeDefaultCountry: 'Japan', officialCurrency: 'JPY', currencySymbol: '¥', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_CHF', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'CHF', braintreeCurrencyConversion: 0.90,  braintreeDefaultCountry: 'Switzerland', officialCurrency: 'CHF', currencySymbol: '₣', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_HKD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'HKD', braintreeCurrencyConversion: 7.90,  braintreeDefaultCountry: 'Hong Kong', officialCurrency: 'HKD', currencySymbol: 'HK$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_TWD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: false, currency: 'TWD', braintreeCurrencyConversion: 33.0,  braintreeDefaultCountry: 'Taiwan', officialCurrency: 'TWD', currencySymbol: 'NT$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR2', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Germany', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true } },
  { path: 'Gundry_SecureCart_EUR3', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Iceland', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true } },
  { path: 'Gundry_SecureCart_EUR_Portgual', component: CheckoutCommon, data: { braintreeLanguageTranslation: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Portgual', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true } },

  { path: 'Gundry_SecureCart_BRL_Brazil', component: CheckoutCommon, data: { braintreeLanguageTranslation: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'BRL', braintreeCurrencyConversion: 5.70, braintreeDefaultCountry: 'Brazil', officialCurrency: 'BRL', currencySymbol: 'R$', braintreeCountryLabel: true } },

  
  

  
  { path: 'Gundry_SecureCart_EUR_FranceCountry', component: CheckoutCommon, data: { braintreeCountrySelector: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'France', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_FranceCountryTranslate', component: CheckoutCommon, data: { braintreeLanguageTranslation: true, braintreeCountrySelector: true, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'France', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },


  { path: 'Gundry_SecureCart_EUR_Austria', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Austria', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Belgium', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Belgium', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Bulgaria', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Bulgaria', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Croatia', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Croatia', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Cyprus', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Cyprus', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_CzechRepublic', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Czech Republic', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Denmark', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Denmark', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Estonia', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Estonia', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Finland', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Finland', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_France', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'France', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Germany', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Germany', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Greece', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Greece', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Hungary', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Hungary', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Ireland', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Ireland', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Italy', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Italy', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Latvia', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Latvia', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Lithuania', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Lithuania', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Luxembourg', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Luxembourg', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Malta', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Malta', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_TheNetherlands', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'The Netherlands', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Poland', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Poland', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Portugal', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Portugal', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Romania', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Romania', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Slovakia', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Slovakia', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Slovenia', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Slovenia', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Spain', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Spain', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_EUR_Sweden', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Sweden', officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  

  // drop in variants
  { path: 'Gundry_SecureCart_Dropin_GBP', component: CheckoutCommon, data: {  braintreeTaxEstimate: 0.2, braintreeEnabled: true, braintreeDropInEnabled: true, euSpecified: true,  currency: 'GBP', braintreeCurrencyConversion: 0.83, braintreeDefaultCountry: 'United Kingdom',  officialCurrency: 'GBP', currencySymbol: '£', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_Dropin_EUR', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: true, euSpecified: true,  currency: 'EUR', braintreeCurrencyConversion: 0.95, braintreeDefaultCountry: 'Germany',  officialCurrency: 'EUR', currencySymbol: '€', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_Dropin_MXN', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: true, euSpecified: false, currency: 'MXN', braintreeCurrencyConversion: 19.5, braintreeDefaultCountry: 'Mexico',  officialCurrency: 'MXN', currencySymbol: 'MX$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_Dropin_USD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: true, euSpecified: false, currency: 'USD', braintreeCurrencyConversion: 1.00, braintreeDefaultCountry: 'United States',  officialCurrency: 'USD', currencySymbol: '$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_Dropin_AUD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: true, euSpecified: false, currency: 'AUD', braintreeCurrencyConversion: 1.54, braintreeDefaultCountry: 'Australia',  officialCurrency: 'AUD', currencySymbol: 'A$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_Dropin_CAD', component: CheckoutCommon, data: { braintreeEnabled: true, braintreeDropInEnabled: true, euSpecified: false, currency: 'CAD', braintreeCurrencyConversion: 1.38, braintreeDefaultCountry: 'Canada',  officialCurrency: 'CAD', currencySymbol: 'A$', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },


  // checkout variants for POC
  { path: 'Gundry_SecureCart_210827', component: CheckoutCommon},
  { path: 'Gundry_SecureCart_Cont_221206', component: CheckoutCommon},
  { path: 'Gundry_SecureCart_BC3_231207', component: CheckoutCommon},
  { path: 'Gundry_SecureCart_Cont_BC3_231207', component: CheckoutCommon},
  { path: 'Gundry_SecureCart_240216', component: CheckoutCommon, data: { checkoutFeature: 'showProductBox' }},
  { path: 'Gundry_SecureCart_Cont_240216', component: CheckoutCommon, data: { checkoutFeature: 'showProductBox' }},


  // upsell variants for POC
  { path: 'CTUS_12-Unit-BioComplete3_AP_221017', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ctus12-unit-bio-complete3-ap221017/ctus12-unit-bio-complete3-ap221017.module').then(m => m.CTUS12UnitBioComplete3AP221017Module) },
  { path: 'CTUS_12-Unit-TotalRestore-BC3-3C_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ctus12-unit-total-restore-bc33-cap/ctus12-unit-total-restore-bc33-cap.module').then(m => m.CTUS12UnitTotalRestoreBC33CAPModule) },
  { path: 'CTUS_3-Unit-BioComplete3_AP_221017', data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ctus3-unit-bio-complete3-ap221017/ctus3-unit-bio-complete3-ap221017.module').then(m => m.CTUS3UnitBioComplete3AP221017Module) },
  { path: 'CTUS_3-Unit-TotalRestore-BC3-1C_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ctus3-unit-total-restore-bc31-cap/ctus3-unit-total-restore-bc31-cap.module').then(m => m.CTUS3UnitTotalRestoreBC31CAPModule) },
  { path: 'CTUS_6-Unit-BioComplete3_AP_221017', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ctus6-unit-bio-complete3-ap221017/ctus6-unit-bio-complete3-ap221017.module').then(m => m.CTUS6UnitBioComplete3AP221017Module) },
  { path: 'CTUS_6-Unit-TotalRestore-BC3-3C_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ctus6-unit-total-restore-bc33-cap/ctus6-unit-total-restore-bc33-cap.module').then(m => m.CTUS6UnitTotalRestoreBC33CAPModule) },
  { path: 'DS_1-Unit-ActiveAdvantage_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ds1-unit-active-advantage-ap/ds1-unit-active-advantage-ap.module').then(m => m.DS1UnitActiveAdvantageAPModule)},
  { path: 'DS_1-Unit-ActiveAdvantage_AP_240111', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds1-unit-active-advantage-ap-240111/ds1-unit-active-advantage-ap-240111.module').then(m => m.DS1UnitActiveAdvantageAP2401110Module) },
  { path: 'DS_1-Unit-BioComplete3_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds1-unit-bio-complete3-ap221020/ds1-unit-bio-complete3-ap221020.module').then(m => m.DS1UnitBioComplete3AP221020Module) },
  { path: 'DS_1-Unit-LectinShield_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ds1-unit-lectin-shield-ap/ds1-unit-lectin-shield-ap.module').then(m => m.DS1UnitLectinShieldAPModule) },
  { path: 'DS_1-Unit-LectinShield_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds1-unit-lectin-shield-ap221020/ds1-unit-lectin-shield-ap221020.module').then(m => m.DS1UnitLectinShieldAP221020Module) },
  { path: 'DS_1-Unit-TotalRestore_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds1-unit-total-restore-ap/ds1-unit-total-restore-ap.module').then(m => m.DS1UnitTotalRestoreAPModule) },
  { path: 'DS_1-Unit-TotalRestore_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds1-unit-total-restore-ap221020/ds1-unit-total-restore-ap221020.module').then(m => m.DS1UnitTotalRestoreAP221020Module) },
  { path: 'DS_1-Unit-VitalReds_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds1-unit-vital-reds-ap/ds1-unit-vital-reds-ap.module').then(m => m.DS1UnitVitalRedsAPModule) },
  { path: 'DS_3-Unit-ActiveAdvantage_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ds3-unit-active-advantage-ap/ds3-unit-active-advantage-ap.module').then(m => m.DS3UnitActiveAdvantageAPModule)},
  { path: 'DS_3-Unit-ActiveAdvantage_AP_240111', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds3-unit-active-advantage-ap-240111/ds3-unit-active-advantage-ap-240111.module').then(m => m.DS3UnitActiveAdvantageAP2401110Module) },
  { path: 'DS_3-Unit-BioComplete3_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds3-unit-bio-complete3-ap221020/ds3-unit-bio-complete3-ap221020.module').then(m => m.DS3UnitBioComplete3AP221020Module) },
  { path: 'DS_3-Unit-LectinShield_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds3-unit-lectin-shield-ap/ds3-unit-lectin-shield-ap.module').then(m => m.DS3UnitLectinShieldAPModule) },
  { path: 'DS_3-Unit-LectinShield_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds3-unit-lectin-shield-ap221020/ds3-unit-lectin-shield-ap221020.module').then(m => m.DS3UnitLectinShieldAP221020Module) },
  { path: 'DS_3-Unit-TotalRestore_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ds3-unit-total-restore-ap/ds3-unit-total-restore-ap.module').then(m => m.DS3UnitTotalRestoreAPModule) },
  { path: 'DS_3-Unit-TotalRestore_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds3-unit-total-restore-ap221020/ds3-unit-total-restore-ap221020.module').then(m => m.DS3UnitTotalRestoreAP221020Module) },
  { path: 'DS_3-Unit-VitalReds_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds3-unit-vital-reds-ap/ds3-unit-vital-reds-ap.module').then(m => m.DS3UnitVitalRedsAPModule) },
  { path: 'DS_6-Unit-ActiveAdvantage_AP_240111', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds6-unit-active-advantage-ap-240111/ds6-unit-active-advantage-ap-240111.module').then(m => m.DS6UnitActiveAdvantageAP2401110Module) },
  { path: 'DS_6-Unit-BioComplete3_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds6-unit-bio-complete3-ap221020/ds6-unit-bio-complete3-ap221020.module').then(m => m.DS6UnitBioComplete3AP221020Module) },
  { path: 'DS_6-Unit-LectinShield_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ds6-unit-lectin-shield-ap/ds6-unit-lectin-shield-ap.module').then(m => m.DS6UnitLectinShieldAPModule) },
  { path: 'DS_6-Unit-LectinShield_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds6-unit-lectin-shield-ap221020/ds6-unit-lectin-shield-ap221020.module').then(m => m.DS6UnitLectinShieldAP221020Module) },
  { path: 'DS_6-Unit-TotalRestore_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./ds6-unit-total-restore-ap/ds6-unit-total-restore-ap.module').then(m => m.DS6UnitTotalRestoreAPModule) },
  { path: 'DS_6-Unit-TotalRestore_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds6-unit-total-restore-ap221020/ds6-unit-total-restore-ap221020.module').then(m => m.DS6UnitTotalRestoreAP221020Module) },  
  { path: 'DS_6-Unit-VitalReds_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./ds6-unit-vital-reds-ap/ds6-unit-vital-reds-ap.module').then(m => m.DS6UnitVitalRedsAPModule) },
  { path: 'US_12-Unit-ActiveAdvantage-GEN_AP_240111', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us12-unit-active-advantage-gen-ap-240111/us12-unit-active-advantage-gen-ap-240111.module').then(m => m.US12UnitActiveAdvantageGENAP240111Module) },
  { path: 'US_12-Unit-LectinShield-BC3-6_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us12-unit-lectin-shield-bc33-ap/us12-unit-lectin-shield-bc33-ap.module').then(m => m.US12UnitLectinShieldBC33APModule) },
  { path: 'US_12-Unit-LectinShield-BC3-6_AP_221020',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./us12-unit-lectin-shield-bc36-ap221020/us12-unit-lectin-shield-bc36-ap221020.module').then(m => m.US12UnitLectinShieldBC36AP221020Module) },
  { path: 'US_12-Unit-TotalRestore-BC3-6_AP_220926', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us12-unit-total-restore-bc36-ap220926/us12-unit-total-restore-bc36-ap220926.module').then(m => m.US12UnitTotalRestoreBC36AP220926Module) },
  { path: 'US_12-Unit-VitalReds-BC3_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./us12-unit-vital-reds-bc3-ap/us12-unit-vital-reds-bc3-ap.module').then(m => m.US12UnitVitalRedsBC3APModule) },
  { path: 'US_3-Unit-ActiveAdvantage-GEN_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us3-unit-active-advantage-gen-ap/us3-unit-active-advantage-gen-ap.module').then(m => m.US3UnitActiveAdvantageGENAPModule) },
  { path: 'US_3-Unit-ActiveAdvantage-GEN_AP_240111', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us3-unit-active-advantage-gen-ap-240111/us3-unit-active-advantage-gen-ap-240111.module').then(m => m.US3UnitActiveAdvantageGENAP240111Module) },
  { path: 'US_3-Unit-LectinShield-BC3-1_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us3-unit-lectin-shield-bc31-ap/us3-unit-lectin-shield-bc31-ap.module').then(m => m.US3UnitLectinShieldBC31APModule) },
  { path: 'US_3-Unit-LectinShield-BC3-1_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us3-unit-lectin-shield-bc31-ap221020/us3-unit-lectin-shield-bc31-ap221020.module').then(m => m.US3UnitLectinShieldBC31AP221020Module) },
  { path: 'US_3-Unit-TotalRestore-BC3-1_AP_220926',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./us3-unit-total-restore-bc31-ap220926/us3-unit-total-restore-bc31-ap220926.module').then(m => m.US3UnitTotalRestoreBC31AP220926Module) },
  { path: 'US_3-Unit-VitalReds-BC3_AP',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./us3-unit-vital-reds-bc3-ap/us3-unit-vital-reds-bc3-ap.module').then(m => m.US3UnitVitalRedsBC3APModule) },
  { path: 'US_6-Unit-ActiveAdvantage-GEN_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us6-unit-active-advantage-gen-ap/us6-unit-active-advantage-gen-ap.module').then(m => m.US6UnitActiveAdvantageGENAPModule) },
  { path: 'US_6-Unit-ActiveAdvantage-GEN_AP_240111', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us6-unit-active-advantage-gen-ap-240111/us6-unit-active-advantage-gen-ap-240111.module').then(m => m.US6UnitActiveAdvantageGENAP240111Module) },
  { path: 'US_6-Unit-LectinShield-BC3-3_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us6-unit-lectin-shield-bc33-ap/us6-unit-lectin-shield-bc33-ap.module').then(m => m.US6UnitLectinShieldBC33APModule) },
  { path: 'US_6-Unit-LectinShield-BC3-3_AP_221020', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us6-unit-lectin-shield-bc33-ap221020/us6-unit-lectin-shield-bc33-ap221020.module').then(m => m.US6UnitLectinShieldBC33AP221020Module) },
  { path: 'US_6-Unit-TotalRestore-BC3-3_AP_220926',data: { animation: 'enterLeavePage' }, loadChildren: () => import('./us6-unit-total-restore-bc33-ap220926/us6-unit-total-restore-bc33-ap220926.module').then(m => m.US6UnitTotalRestoreBC33AP220926Module) },
  { path: 'US_6-Unit-VitalReds-BC3_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us6-unit-vital-reds-bc3-ap/us6-unit-vital-reds-bc3-ap.module').then(m => m.US6UnitVitalRedsBC3APModule) },
  

  ];

@Injectable({ providedIn: "root" })
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(public router: Router) {
    console.log("[GH] AppRoutingModule constructor " + document.location.origin  +'/assets/html/a18tests.html');
    let content = {};
    let logic = (content:any) => {
      if (Object.keys(content).length === 0) {
        console.log("[GH] Error getting upsell dynamic content");
        this.routeHandlerFinally();
        return;
      } else {
        console.log("[GH] Got upsell dynamic content", content);
      }
      for (let prop in content) {
        if (prop == "fileListStream") {
          for (const key in (content as any)[prop]) {
            if ((content as any)[prop].hasOwnProperty(key)) {
             /*  console.log(
                `[GH] push this key? ${key}`,
                (content as any)[prop][key]
              ); */
              //console.log('this.router.config', ...this.router.config);

              let alreayAdded = false;
              for(let i = 0; i < this.router.config.length; i++) {
                if(this.router.config[i].path == (content as any)[prop][key].url) {
                  //console.log('route already added ' + (content as any)[prop][key].url);
                  alreayAdded = true;
                  break;
                }
              }
              if(alreayAdded) {
                continue;
              }

              if (typeof (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent == 'object') {
                let isvalid = false;
                for(let i = 0; i < (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent.length; i++) {
                  //console.log('checking '+(content as any)[prop][key].url, (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent[i])
                  if((window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent[i] == 'upDownDyn-' + (content as any)[prop][key].url + '.json' ) {
                    isvalid = true;
                    break;
                  }
                }
                if(!isvalid) {
                  continue;
                }
                this.router.config.push({
                  path: (content as any)[prop][key].url,
                  component: UpsellDynamic,
                  data: { animation: "enterLeavePage" },
                });
              } else {
                console.log('upsellDynamicCMSControl.dynamicPageNamesWithContent is not an array');
              }
            }
          }
        }
      }
      this.routeHandlerFinally();
    }
    if((window as any)['upsellDynamicContent']) {
      logic((window as any)['upsellDynamicContent']);
      return;
    }
    let xhr = new XMLHttpRequest();
    xhr.open("GET", "/assets/content/store_dynamic_upsell_control.json", false);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    xhr.onload = () => {
      if (typeof xhr.response == "string") content = JSON.parse(xhr.response);
      else if (typeof xhr.response == "object") content = xhr.response;
      logic(content);
    };
    xhr.onerror = (error) => {
      console.log(`XHR Error: ${error}`);
      this.routeHandlerFinally();
    };

    xhr.send();
  }

  routeHandlerFinally() {
    // Wildcard route TODO add a not found handler
    this.router.config.push({
      path: "**",
      component: OopsComponent,
      data: { animation: "enterLeavePage" },
    });
  }
}
